import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import queryString from 'query-string'
import styled from 'styled-components'

const withPreview = (Component) => {
  const preview = (props) => {
    const {
      location: { search },
    } = props
    const parsed = queryString.parse(search)
    const {
      preview: parsedPreview,
      post,
      nonce,
      path,
      post_type: postType,
    } = parsed

    const id = parseInt(post, 10)

    if (!parsedPreview) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component preview={false} {...props} />
    }

    return (
      <PostPreview
        component={Component}
        nonce={nonce}
        path={path}
        postType={postType}
        props={props}
        id={id}
      />
    )
  }

  return preview
}

function PostPreview({
  id,
  component: Component,
  props,
  nonce,
  path,
  postType,
}) {
  const {
    site: {
      siteMetadata: { siteWpUrl },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  const [post, setPost] = useState(null)

  async function fetchPost() {
    try {
      const response = await fetch(
        `${siteWpUrl}/wp-json/wp/v2/${path}/${id}/revisions`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'X-WP-Nonce': nonce,
          },
        }
      )

      if (response.status >= 400 && response.status <= 600) {
        return { data: null, error: response.status }
      }

      try {
        const data = await response.json()

        return data
      } catch (error) {
        return null
      }
    } catch (e) {
      return null
    }
  }

  useEffect(() => {
    fetchPost().then((p) => setPost(p))
  }, [])

  if (post === null) {
    return <Loading description="Voorbeeld aan het laden" />
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props} postPreview={post[0]} postType={postType} />
}

const StyledLoading = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Loading = ({ description }) => (
  <StyledLoading>
    <h1>{description} ...</h1>
  </StyledLoading>
)

export default withPreview
